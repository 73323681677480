import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { modules } from "../lib/data/modules";
import { layerID } from "../lib/layer_utils";
import { LatLongDecimalArray } from "../lib/map";
import { ControlMeasure } from "../lib/models/ControlMeasure";
import { FragOrderMapFeature } from "../lib/models/FragOrderMapFeature";
import { Plan, PlanStatus } from "../lib/models/Plan";
import {
  PublishManifestDoc,
  TaskingState,
} from "../lib/models/PublishManifest";
import { NavPointController } from "../lib/services/NavPointController";
import { PlanningController } from "../lib/services/PlanningController";
import { createHighContrastColorMap } from "../lib/tasking";
import {
  ASSET_VIEWER_PARAM,
  DCSWaypoint,
  FeatureClass,
  TaskingMapLayerName,
} from "../lib/types";
import { useNotification } from "./NotifcationContext";
import { useSubmitPlan } from "./PlanManagerContext";
import { useUser } from "./UserContext";

type HopupData = {
  layerName: string;
  title: string;
  content: string;
  coordinates: LatLongDecimalArray;
};

type MapHopupState = {
  visible: boolean;
  coordinates: LatLongDecimalArray | null;
  features: HopupData[];
};

export enum DrawMode {
  None,
  Freehand,
  Ruler,
  PointDrop,
}

export type TaskingMapState = {
  manifest: PublishManifestDoc;
  contextualHopup: MapHopupState;
  focusedFeature?: {
    layerName: string;
    name: string | number;
    feature?: FragOrderMapFeature;
    featureClass?: FeatureClass;
    pageableFeatures: FeatureData[];
  };
  tasking: TaskingState;
  userPlannableGroups: string[];
  openDrawerValue: TaskingMenuDrawer;
  soloGroup?: string;
  drawMode: DrawMode;
  planningMode: boolean;
  plans: Plan[];
  waypointDropMode: boolean;
  dataEntryGroup: string | null;
  dataEntryEnabled: boolean;
  assetViewer: {
    open: boolean;
  };
  disabledLayers: string[];
  colorMap: Record<string, string>;
  disableDistances: boolean;
  disableWaypointLabels: boolean;
  editingControlMeasure?: ControlMeasure;
  navPointMode: {
    enabled: boolean;
    group: string;
  };
  compassVisible: boolean;
};

type TaskingMapContextType = {
  state: TaskingMapState;
  dispatch: React.Dispatch<TaskingMapActions>;
  controller: React.MutableRefObject<PlanningController>;
  navPointController: React.MutableRefObject<NavPointController>;
};

export enum TaskingMapActionTypes {
  focusFeature,
  blurFeature,
  toggleDrawer,
  soloGroup,
  setDrawMode,
  mapReady,
  togglePlanning,
  enableWaypointDropMode,
  disableWaypointDropMode,
  setPlanStatus,
  toggleGroupLayers,
  toggleMapLayers,
  togglePlanningDataEntry,
  toggleAssetViewer,
  toggleLayer,
  disableDistances,
  disableWaypointLabels,
  toggleEditControlMeasure,
  toggleNavPointMode,
  toggleCompassViz,
}

export enum TaskingMenuDrawer {
  PlayerGroups,
  Planning,
  Airbases,
  SupportAssets,
}

export type FeatureData = {
  layerName: string;
  feature?: FragOrderMapFeature;
};

export type TaskingMapActions =
  | {
      type: TaskingMapActionTypes.focusFeature;
      layerName: string;
      name: string | number;
      featureClass?: FeatureClass;
      feature?: FragOrderMapFeature;
      otherFeatures: FeatureData[];
    }
  | {
      type: TaskingMapActionTypes.blurFeature;
    }
  | {
      type: TaskingMapActionTypes.toggleDrawer;
      value: number;
    }
  | {
      type: TaskingMapActionTypes.soloGroup;
      groupName: string;
    }
  | {
      type: TaskingMapActionTypes.setDrawMode;
      mode: DrawMode;
    }
  | {
      type: TaskingMapActionTypes.mapReady;
    }
  | {
      type: TaskingMapActionTypes.togglePlanning;
      enabled: boolean;
    }
  | {
      type: TaskingMapActionTypes.enableWaypointDropMode;
    }
  | {
      type: TaskingMapActionTypes.disableWaypointDropMode;
    }
  | {
      type: TaskingMapActionTypes.toggleGroupLayers;
      groups: string[];
      opacity: number;
    }
  | {
      type: TaskingMapActionTypes.toggleMapLayers;
      layers: string[];
      opacity: number;
    }
  | {
      type: TaskingMapActionTypes.setPlanStatus;
      groupName: string;
      status: PlanStatus;
    }
  | {
      type: TaskingMapActionTypes.togglePlanningDataEntry;
      enabled: boolean;
      groupName?: string;
    }
  | {
      type: TaskingMapActionTypes.toggleAssetViewer;
      open: boolean;
    }
  | {
      type: TaskingMapActionTypes.toggleLayer;
      layers: string[];
      enabled: boolean;
    }
  | {
      type: TaskingMapActionTypes.disableDistances;
      enabled: boolean;
    }
  | {
      type: TaskingMapActionTypes.disableWaypointLabels;
      enabled: boolean;
    }
  | {
      type: TaskingMapActionTypes.toggleEditControlMeasure;
      controlMeasure: ControlMeasure;
    }
  | {
      type: TaskingMapActionTypes.toggleNavPointMode;
      group: string;
    }
  | {
      type: TaskingMapActionTypes.toggleNavPointMode;
      group: string;
      enabled: boolean;
    }
  | {
      type: TaskingMapActionTypes.toggleCompassViz;
      enabled: boolean;
    };

export function reducer(
  state: TaskingMapState,
  action: TaskingMapActions
): TaskingMapState {
  switch (action.type) {
    case TaskingMapActionTypes.focusFeature:
      return {
        ...state,
        focusedFeature: {
          layerName: action.layerName,
          name: action.name,
          featureClass: action.featureClass,
          pageableFeatures: action.otherFeatures,
        },
        dataEntryGroup:
          action.layerName === "Player Groups"
            ? (action.name as string)
            : state.dataEntryGroup,
      };
    case TaskingMapActionTypes.blurFeature:
      return {
        ...state,
        focusedFeature: null,
      };

    case TaskingMapActionTypes.toggleDrawer:
      return {
        ...state,
        openDrawerValue: action.value,
      };

    case TaskingMapActionTypes.setDrawMode:
      return {
        ...state,
        drawMode: action.mode,
        waypointDropMode:
          action.mode === DrawMode.None ? false : state.waypointDropMode,
      };

    case TaskingMapActionTypes.mapReady:
      // This is just here to force a re-render when the map is created
      return {
        ...state,
      };

    case TaskingMapActionTypes.togglePlanning:
      return {
        ...state,
        planningMode: action.enabled,
      };

    case TaskingMapActionTypes.enableWaypointDropMode:
      return {
        ...state,
        waypointDropMode: true,
      };

    case TaskingMapActionTypes.disableWaypointDropMode:
      return {
        ...state,
        waypointDropMode: false,
      };

    case TaskingMapActionTypes.setPlanStatus:
      return {
        ...state,
        plans: _.set(
          [...state.plans],
          [_.findIndex(state.plans, { groupName: action.groupName }), "status"],
          action.status
        ),
      };

    case TaskingMapActionTypes.togglePlanningDataEntry:
      return {
        ...state,
        dataEntryGroup: action.enabled ? action.groupName : null,
        dataEntryEnabled: action.enabled,
      };

    case TaskingMapActionTypes.toggleAssetViewer:
      return {
        ...state,
        assetViewer: {
          ...state.assetViewer,
          open: action.open,
        },
      };

    case TaskingMapActionTypes.toggleLayer:
      return {
        ...state,
        disabledLayers: nextLayers(state, action.layers, action.enabled),
      };

    case TaskingMapActionTypes.disableDistances:
      return {
        ...state,
        disableDistances: action.enabled,
      };

    case TaskingMapActionTypes.disableWaypointLabels:
      return {
        ...state,
        disableWaypointLabels: action.enabled,
      };

    case TaskingMapActionTypes.toggleEditControlMeasure:
      return {
        ...state,
        editingControlMeasure: action.controlMeasure || null,
      };

    case TaskingMapActionTypes.toggleNavPointMode:
      return {
        ...state,
        focusedFeature: null,
        navPointMode: {
          enabled: !state.navPointMode.enabled,
          group: action.group,
        },
      };

    case TaskingMapActionTypes.toggleCompassViz:
      return {
        ...state,
        compassVisible: action.enabled,
      };

    default:
      break;
  }

  return state;
}

function nextLayers(
  state: TaskingMapState,
  actionLayer: string[],
  enabled?: boolean
): string[] {
  const nextLayers = [...state.disabledLayers];

  _.each(actionLayer, (l) => {
    if (!_.isUndefined(enabled)) {
      if (enabled) {
        _.pull(nextLayers, l);
      } else {
        nextLayers.push(l);
      }

      return;
    }

    if (_.includes(nextLayers, l)) {
      _.pull(nextLayers, l);
    } else {
      nextLayers.push(l);
    }
  });

  return _.uniq(nextLayers);
}

type Props = {
  children?: any;
  tasking: TaskingState;
  publish: PublishManifestDoc;
  plannableGroups: string[];
  controlMeasures: ControlMeasure[];
};

export const TaskingMapContext = React.createContext<TaskingMapContextType>(
  {} as TaskingMapContextType
);

function initialPlans(
  manifest: PublishManifestDoc,
  tasking: TaskingState,
  plannableGroups: string[]
): Plan[] {
  const groups = _.filter(tasking.plannedGroups, (g) =>
    _.includes(plannableGroups, g.name)
  );

  const plans: Plan[] = [];
  _.each(groups, (g) => {
    plans.push({
      coalition: manifest.coalition,
      fragOrderID: manifest.fragOrderID,
      groupName: g.name,
      groupCategory: g.category,
      waypoints: g.waypoints,
      status: PlanStatus.None,
      moduleName: _.get(g.units, [0, "type"]) as keyof typeof modules,
    });
  });

  return plans;
}

export function defaultState(
  manifest: PublishManifestDoc,
  tasking: TaskingState,
  plannableGroups: string[],
  history?: any
): TaskingMapState {
  const assetViewOpen =
    new URLSearchParams(history?.location.search).get(ASSET_VIEWER_PARAM) ===
    "true";

  return {
    manifest,
    contextualHopup: {
      visible: false,
      coordinates: null,
      features: [],
    },
    focusedFeature: null,
    tasking,
    userPlannableGroups: plannableGroups,
    openDrawerValue: null,
    drawMode: DrawMode.None,
    planningMode: false,
    plans: initialPlans(manifest, tasking, plannableGroups),
    waypointDropMode: false,
    dataEntryGroup: null,
    dataEntryEnabled: false,
    assetViewer: {
      open: assetViewOpen,
    },
    disabledLayers: [],
    colorMap: createHighContrastColorMap(_.map(tasking.plannedGroups, "name")),
    disableDistances: true,
    disableWaypointLabels: false,
    editingControlMeasure: null,
    navPointMode: {
      enabled: false,
      group: null,
    },
    compassVisible: false,
  };
}

export function TaskingMapProvider({
  children,
  tasking,
  plannableGroups,
  publish,
}: Props) {
  const history = useHistory();
  const controller = React.useRef<PlanningController>(null);
  const navPointController = React.useRef<NavPointController>(null);
  const initialState: TaskingMapState = defaultState(
    publish,
    tasking,
    plannableGroups,
    history
  );

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <TaskingMapContext.Provider
      value={{
        state: state as TaskingMapState,
        dispatch,
        controller,
        navPointController,
      }}
    >
      {children}
    </TaskingMapContext.Provider>
  );
}

export function useTaskingState() {
  return React.useContext(TaskingMapContext);
}

export function useUserIsPlanner() {
  const { state } = useTaskingState();
  return state.userPlannableGroups.length > 0;
}

function canPlan(state: TaskingMapState, groupName: string) {
  return _.includes(state.userPlannableGroups, groupName);
}

export function userCanPlanGroup(groupName: string) {
  const { state } = React.useContext(TaskingMapContext);
  return canPlan(state, groupName);
}

export function useFocusFeature() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (
    layerName: string,
    name: string | number,
    featureClass?: FeatureClass,
    otherFeatures: FeatureData[] = []
  ) => {
    dispatch({
      type: TaskingMapActionTypes.focusFeature,
      layerName,
      name,
      otherFeatures,
      featureClass,
    });
  };
}

export function useBlurFeature() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return () => {
    dispatch({
      type: TaskingMapActionTypes.blurFeature,
    });
  };
}

export function useFocusWaypoint() {
  const select = useFocusFeature();

  return (groupName: string, wp: DCSWaypoint) =>
    select(groupName, wp?.number, FeatureClass.Waypoint);
}

export function useToggleDataEntry() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (enabled: boolean, groupName?: string) => {
    dispatch({
      type: TaskingMapActionTypes.togglePlanningDataEntry,
      groupName,
      enabled,
    });
  };
}

export function useSoloGroup() {
  const {
    state: {
      tasking: { plannedGroups },
    },
  } = React.useContext(TaskingMapContext);
  const toggle = useToggleLayer();

  return (groupName: string) => {
    const rest = _.filter(plannedGroups, (g) => g.name != groupName);
    const layers = _.map(rest, (g) =>
      layerID(TaskingMapLayerName.PlayerGroups, [g.name])
    );

    toggle(layers, false);
    toggle([layerID(TaskingMapLayerName.PlayerGroups, [groupName])], true);
  };
}

export function useToggleAssetViewer() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (open: boolean) =>
    dispatch({
      type: TaskingMapActionTypes.toggleAssetViewer,
      open,
    });
}

export function useSetDrawMode() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (mode: DrawMode) => {
    dispatch({
      type: TaskingMapActionTypes.setDrawMode,
      mode,
    });
  };
}

export function useToggleLayer() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (layers: string[], enabled?: boolean) => {
    dispatch({
      type: TaskingMapActionTypes.toggleLayer,
      layers,
      enabled,
    });
  };
}

export function useGetColorMap() {
  const { state } = React.useContext(TaskingMapContext);

  return state?.colorMap || {};
}

export function useGetColorForGroup(groupName: string) {
  const cm = useGetColorMap();

  return cm[groupName];
}

export function useAutoSubmitPlans() {
  const { state, controller } = React.useContext(TaskingMapContext);
  const { success, error } = useNotification();
  const submit = useSubmitPlan(
    state.manifest,
    state.tasking.theater,
    controller.current
  );

  React.useEffect(() => {
    const p = _.filter(state.plans, { status: PlanStatus.Draft });

    if (p.length === 0) {
      return;
    }

    const work = _.map(p, (p) => {
      const g = _.find(state.tasking.plannedGroups, { name: p.groupName });

      if (g) {
        return submit(g);
      }
    });

    Promise.all(work)
      .then(() => success("Plan updated"))
      .catch((e) => {
        error(`Error updating plan: ${e.message}`);

        throw e;
      });
  }, [state.plans]);
}

export function useTogglePlanningMode() {
  const { dispatch, state } = React.useContext(TaskingMapContext);

  return (enabled?: boolean) => {
    dispatch({
      type: TaskingMapActionTypes.togglePlanning,
      enabled: typeof enabled !== "undefined" ? enabled : !state.planningMode,
    });
  };
}

export function useUserCanDrawControlMeasures() {
  const { currentUser } = useUser();
  const {
    state: { manifest },
  } = React.useContext(TaskingMapContext);

  if (!currentUser) {
    return false;
  }

  return !!_.find(
    manifest.controlMeasureEditors,
    (e) => e.discordUserID === currentUser.id
  );
}

export function useToggleControlMeasureEdit() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (controlMeasure?: ControlMeasure) => {
    dispatch({
      type: TaskingMapActionTypes.toggleEditControlMeasure,
      controlMeasure,
    });
  };
}

export function useToggleNavPointMode() {
  const { dispatch } = React.useContext(TaskingMapContext);

  return (group: string) => {
    dispatch({
      type: TaskingMapActionTypes.toggleNavPointMode,
      group,
    });
  };
}

export function useNavPointController() {
  const { navPointController } = React.useContext(TaskingMapContext);
  return navPointController.current;
}
