import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { getEntireExtent } from "../../lib/extent";
import { RouteDetailKneeboardSettings } from "../../lib/models/Job";
import {
  KNEEBOARD_HEIGHT,
  KNEEBOARD_WIDTH,
  renderMap,
} from "./kneeboard_utils";

import * as React from "react";
import { TaskingState } from "../../lib/models/PublishManifest";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import { NewImageComposer } from "../../lib/services/ImageComposer";
import GroupsWithWaypoints from "../MapCanvas/GroupWithRoute";
import { MizDrawingLayer } from "../MapCanvas/MizDrawingLayer";
import SharedNavPointsLayer from "../MapCanvas/SharedNavPointsLayer";
import { StickMapLayer } from "../MapCanvas/StickMapLayer";
import ThreatLayer from "../MapCanvas/ThreatLayer";

export async function renderRouteDetail(
  tasking: TaskingState,
  group: GroupWithPlan,
  settings?: RouteDetailKneeboardSettings
) {
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);

  const el = (
    <div
      style={{
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <h2>{group?.name}</h2>
    </div>
  );

  const g = NewHTMLImageGenerator();

  const blob = await g.renderToBlob(el, {
    height: 200,
    width: 400,
    fillColor: "rgba(0, 0, 0, 0.0)",
  });

  const extent = getEntireExtent(tasking.theater, [group]);
  const mapImg = (await renderMap(
    extent,
    { height: KNEEBOARD_HEIGHT, width: KNEEBOARD_WIDTH, zoomAdjust: -0.3 },
    <>
      <ThreatLayer theater={tasking.theater} groups={tasking.opforVehicles} />
      {/* <ControlMeasuresRenderLayer measures={tasking} /> */}
      <MizDrawingLayer
        theater={tasking.theater}
        drawings={tasking.drawingData}
      />
      <GroupsWithWaypoints
        groups={[group]}
        theater={tasking.theater}
        colorOverride="black"
        disableDistanceLabels={!settings?.showDistances}
        declutter={false}
        renderIcon
      />
      <SharedNavPointsLayer
        theater={tasking.theater}
        bullseye={tasking.bullseye}
      />
      <StickMapLayer
        theater={tasking.theater}
        bulls={tasking.bullseye}
        visible={settings?.showBullsCompass}
      />
    </>
  )) as Blob;

  composer.add(mapImg, {
    x: 0,
    y: 0,
  });

  composer.add(blob, {
    x: 24,
    y: 0,
  });

  return composer;
}
